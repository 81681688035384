<template>
<transition name="fadePage" mode="out-in">
<div class="assetDetails PurchasingComputing">
  <Top-Nav></Top-Nav>
  <div class="common_title">{{$t('menu.yccsl')}}</div>
  <div class="node_table" :style="boxHeightObj">
    <div class="list_wrapper" :style="boxHeightObj">
      <div class="list" v-for="(item,index) in listData" :key="index">

        <div class="num_a mt_com"  v-if="item.types == 6"> + {{formatPowMethod(item.amount)}}</div>
        <div class="num_a mt_com"  v-if="item.types < 6"> - {{formatAmountMethod(item.amount2)}}</div>

        <div class="time_a_com"> {{formatTypesMethod(item.types)}} <br/>{{formatDateTimeMethod(item.time)}} </div>
      </div>
    </div>
  </div> 
</div>
</transition>
</template>
<script>
import '../styles/common.css';
import '../../../../style/assetDetails.css';
import TopNav from '../common/PurchasingTop.vue';
const poolTwo = require('../../../../utils/poolTwo');
export default {
  name: 'PurchasingComputing',
  components:{TopNav},
  data() {
    return {
      formatTypesMethod:this.formatTypes,
      formatAmountMethod:poolTwo.amountConversion,
      formatPowMethod:this.formatPow, // 格式化算力
      formatDateTimeMethod:poolTwo.getTime,
      windowHeight: window.innerHeight,   //屏幕高度
      boxHeightObj:{  //背景框的高度
        height:0
      },
      listData:[
      //   {
      //   num:"+1029.92",
      //   date:"2022-05-11  20:19:42"
      // },{
      //   num:"+1029.92",
      //   date:"2022-05-11  20:19:42"
      // },{
      //   num:"+1029.92",
      //   date:"2022-05-11  20:19:42"
      // }
      ],
      usdtPercentage:60,
    }
  },
  created:function(){
    let that = this
    let myAddress = localStorage.getItem('address')
    if(!myAddress || typeof myAddress == "undefined" || myAddress == 0){
      //console.log('没有钱包地址不获取数据',myAddress)
      return 
    }
    // 获取数据
    let types_in = '[6]'
    let query = `query MyQuery {
        withdrawModels(
          orderBy: time
          orderDirection: desc
          where: {types_in: `+types_in+`, account: "`+myAddress+`"}
        ) {
          amount
          amount2
          time
          types
        }
      }`
    // 挖出流水
    // types 1. 提现节点奖励
    // types 2. 节点质押， 3解除质押
    // type 4. 推荐奖励提现
    // type 5. 算力挖矿奖励
    // type 6. 质押
    // 提现类型的操作，amount是token，amount2是token的usdt价值
    // type=6质押的时候，amount是usdt数量，amount2是seed数量
    poolTwo.graphql(query).then(res=>{
      console.log('挖出流水',res)
      let withdrawModels = res.data.withdrawModels
      console.log(withdrawModels)
      that.listData = withdrawModels
    })

    // 获取usdt比例
    poolTwo.usdtPercentage().then(res => {
      that.usdtPercentage = res
    })
  },
  mounted(){
    this.boxHeightObj.height = (this.windowHeight/10)-5-7.8-3.2+'rem';
  },
  methods:{
    /**
     * 格式化算力（ usdt*6=获得算力 ）
     * @param {*} _pow 
     */
    formatPow(_pow){
      return poolTwo.amountConversion(_pow) / this.usdtPercentage *100 * 3
    },
    formatTypes(type){
      switch (type) {
        case 1:
          return this.$t('menu.cashWitNodeReward')  //提现节点奖励
        case 2:
          return this.$t('menu.nodePledge') //节点质押
        case 3:
          return this.$t('menu.releasePledge') //解除质押
        case 4:
          return this.$t('menu.recommendedReWith') //推荐奖励提现 换成了 社区提现
        case 5:
          return this.$t('menu.computingPowerReward') //算力挖矿奖励 换成了 算力提现
        case 6:
          // return this.$t('menu.pledge') //质押
          return this.$t('menu.calcPowerExchange') //算力兑换 
      }
    }
  }
}

</script>
<style scoped>

</style>